import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Report = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { studentID } = parsedLocation;
  useRedirectHandler(() => {
    if (studentID) {
      return `https://home.classdojo.com/#/reports/${studentID}`;
    } else {
      return "https://home.classdojo.com/#/story";
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Report;
